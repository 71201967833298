<template>
  <div class="CreditsCardContent">
    <UserCreditsCard :modal="modal" />
  </div>
</template>

<script>
export default {
  props: ["modal"],
  components: {
    UserCreditsCard: () => import("../user/UserCreditsCard.vue"),
  },
};
</script>

<style lang="scss">
.CreditsCardMenu {
  .CreditsCardContent {
    position: relative;
  }
}
</style>
